<template>
  <v-layout>
    <!-- // TODO: Костыль
      :sub-title="
        OrganizationDocumentStatus[dataSource.Status + 1]
          ? OrganizationDocumentStatus[dataSource.Status + 1].value
          : ''
      "
     -->
    <base-detail-view
      :loading="loading"
      :title="
        this.type === 'create'
          ? 'Создание локального документа'
          : this.type === 'copy'
          ? 'Копирование локального документа'
          : title
      "
      :sub-title="
        OrganizationDocumentStatus[dataSource.Status + 1]
          ? OrganizationDocumentStatus[dataSource.Status + 1].value
          : ''
      "
      :isUnsaved="isUnsaved"
      @onSave="
        save();
        $refs.fileLibrary.$refs.rich &&
          $refs.fileLibrary.$refs.rich.saveDocument();
      "
      @onRefresh="init"
    >
      <template slot="action">
        <document-acquaintance
          ref="acquaintance"
          :documentId="dataSource.Id"
        ></document-acquaintance>
        <document-history
          ref="history"
          :documentId="dataSource.Id"
          :readonly="!editHistory"
        ></document-history>
        <v-btn
          @click="$refs.fileLibrary.$refs.rich.documentPrint()"
          icon
          title="Распечатать"
        >
          <m-icon icon="tool-print" s16 />
        </v-btn>
        <!-- Действия автора -->
        <v-btn
          v-if="activeAuthorDraft"
          :disabled="isUnsaved"
          @click="authorDraft()"
          dark
          color="primary"
          class="ml-4"
        >
          Вернуть на доработку
        </v-btn>
        <menu-button
          v-if="activeAuthorOnAgreement"
          hasComment
          :disabled="!dataSource.Soglasuyut.length || isUnsaved"
          @click="authorOnAgreement($event)"
          caption="Передача документа на согласование"
          text="Передать на согласование"
          :fields="hasAgreementSigns ? 'resetSigned' : ''"
        ></menu-button>
        <menu-button
          v-if="activeAuthorOnApproval"
          hasComment
          :disabled="!dataSource.Podpisyvaet_utverzhdaet || isUnsaved"
          @click="authorOnApproval($event)"
          caption="Передача документа на утверждение"
          text="Передать на утверждение"
        ></menu-button>
        <!-- Действия согласующего -->
        <v-btn
          v-if="activeAgreementerAgree"
          :disabled="isUnsaved"
          @click="agreementerAgree()"
          dark
          color="primary"
          class="ml-4"
        >
          Согласовать
        </v-btn>
        <menu-button
          v-if="activeAgreementerDisagree && !activeAuthorDraft"
          hasComment
          :disabled="isUnsaved"
          @click="agreementerDisagree($event)"
          caption="Несогласие с документом"
          text="Вернуть на доработку"
        ></menu-button>
        <v-btn
          v-if="activeAgreementerCancel"
          :disabled="isUnsaved"
          @click="agreementerCancel()"
          dark
          color="primary"
          class="ml-4"
        >
          Отменить решение
        </v-btn>
        <!-- Действия утверждающего -->
        <v-btn
          v-if="activeApproverApprove"
          :disabled="isUnsaved"
          @click="approverApprove()"
          dark
          color="primary"
          class="ml-4"
        >
          Утвердить
        </v-btn>
        <menu-button
          v-if="activeApproverOnAgreement"
          hasComment
          :disabled="isUnsaved"
          @click="approverOnAgreement($event)"
          caption="Необходимо согласование документа"
          text="Передать на согласование"
          fields="employees"
          :dataEmployeesSource="filterEmployees"
        ></menu-button>
        <menu-button
          v-if="activeApproverToDraft && !activeAuthorDraft"
          hasComment
          :disabled="isUnsaved"
          @click="approverToDraft($event)"
          caption="Несогласие с документом"
          text="Вернуть на доработку"
        ></menu-button>
        <v-btn
          v-if="activeApproverCancel"
          :disabled="isUnsaved"
          @click="approverCancel()"
          dark
          color="primary"
          class="ml-4"
        >
          Отменить утверждение
        </v-btn>
        <!-- Действия ознакомляющего -->
        <v-btn
          v-if="activeAcquainted"
          :disabled="isUnsaved"
          @click="setAcquainted()"
          dark
          color="primary"
          class="ml-4"
        >
          Ознакомиться
        </v-btn>
        <!-- Регистрация и отмена документа -->
        <v-btn
          v-if="activeActive"
          :disabled="isUnsaved"
          @click="setActive()"
          dark
          color="primary"
          class="ml-4"
        >
          Зарегистрировать
        </v-btn>
        <menu-button
          v-if="activeCancelled"
          hasComment
          :disabled="isUnsaved"
          @click="setCancelled($event)"
          caption="Отмена документа"
          text="Отменить документ"
        ></menu-button>
      </template>
      <div class="local-document-edit__help-panel">
        <v-btn icon @click="isShowPanel = !isShowPanel">
          <m-icon
            :icon="isShowPanel ? 'tool-arrow-up' : 'tool-arrow-down'"
            s16
          />
        </v-btn>
      </div>
      <v-form v-show="isShowPanel" class="block__content" style="z-index: 100">
        <v-container>
          <v-row v-if="dataSource.Fields">
            <v-col>
              <dynamic-inputs
                :fields="dataSource.Fields.filter((e, i) => !(i % 2))"
                @update="updateField"
              />
            </v-col>
            <v-col>
              <dynamic-inputs
                :fields="dataSource.Fields.filter((e, i) => i % 2)"
                @update="updateField"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>

      <m-tabs v-model="tab">
        <v-tab key="Document">Документы</v-tab>
        <v-tab key="Tasks">Задачи</v-tab>
      </m-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item key="Document">
          <fileLibrary
            :fetchText="DocumentTemplateService.show"
            :files.sync="dataSource.Files"
            :id="dataSource.Id"
            :content.sync="dataSource.Content"
            :isContentVisible.sync="dataSource.IsContentVisible"
            :templateId="dataSource.TemplateId"
            :init="init"
            :save="save"
            :saveAddText="saveAddText"
            :fields="fields"
            :showAdd="
              (dataSource.Status !== 5 && dataSource.Status !== 6) || activeAdd
            "
            :notShowDelete="
              (dataSource.Status === 5 || dataSource.Status === 6) && !activeAdd
            "
            @documentChanged="documentChanged"
            ref="fileLibrary"
          >
            <template slot="info">
              <div style="padding: 50px 0; text-align: center">
                {{
                  (dataSource.Status !== 5 && dataSource.Status !== 6) ||
                  activeAdd
                    ? "Создайте текст документа на основе шаблона или добавьте файл готового документа в формате word или pdf"
                    : "Документ зарегистрирован, добавлять документы запрещено!"
                }}
              </div>
            </template>
          </fileLibrary>
        </v-tab-item>
        <v-tab-item key="Tasks">
          <task-list
            class="mt-2"
            :Tasks.sync="dataSource.Tasks"
            style="margin-bottom: 0 !important"
            :isFromTask="false"
            :apiService="TaskService.document"
            @afterDelete="originObject.Tasks = [...dataSource.Tasks]"
            ref="taskList"
            typeParent="document"
          ></task-list>
        </v-tab-item>
      </v-tabs-items>
    </base-detail-view>
  </v-layout>
</template>

<script>
import LocalDocumentService from "../../services/LocalDocumentService";
import TaskService from "../../services/TaskService";
import DocumentTemplateService from "../../services/document/DocumentTemplateService";
import BaseDetailView from "../../layouts/BaseDetailView.vue";
import UnsavedMixin from "../../mixins/UnsavedMixin.js";
import CreateAndEditMixin from "../../mixins/CreateAndEditMixin.js";
import { defaultLocalDocument } from "../../data/defaultObjects";
import {
  OrganizationDocumentType,
  OrganizationDocumentStatus,
} from "../../data/enums";
import { mapActions } from "vuex";

import DynamicInputs from "@/components/DynamicInputs.vue";
import MenuButton from "./components/MenuButton.vue";
import DocumentHistory from "./components/DocumentHistory.vue";
import DocumentAcquaintance from "./components/DocumentAcquaintance.vue";
import TaskList from "@/components/task/TaskList";
import mTabs from "@/components/base/tabs/mTabs";

import { debounce } from "lodash";
import Permissions from "../../data/permissions";

import fileLibrary from "@/components/FileLibrary.vue";

const statusDraft = 0;
const statusOnAgreement = 1;
const statusAgreed = 2;
const statusOnApproval = 3;
const statusApproved = 4;
const statusActive = 5;
const statusCancelled = 6;

export default {
  name: "view-LocalDocumentEdit",
  components: {
    BaseDetailView,
    DynamicInputs,
    MenuButton,
    DocumentHistory,
    DocumentAcquaintance,
    fileLibrary,
    TaskList,
    mTabs,
  },
  mixins: [CreateAndEditMixin, UnsavedMixin],
  data: function () {
    return {
      showDocument: null,
      isShowPanel: true,
      // Api
      apiService: LocalDocumentService,
      getDefaultObject: defaultLocalDocument,
      loading: true,
      // from dateSources
      selectEmployees: [],
      employees: [],
      partitions: [],
      projects: [],
      dataSource: defaultLocalDocument(),
      Content: null,
      getObjectText: () => this.title,
      showTabs: false,
      tab: 0,
    };
  },
  computed: {
    TaskService() {
      return TaskService;
    },
    filterEmployees() {
      const filteredEmployees = this.employees.filter((e) => {
        return !this.dataSource.Soglasuyut?.some(
          (e2) => e?.UserId === e2.UserId
        );
      });

      return filteredEmployees;
    },

    title() {
      return (
        (this.dataSource.Type ? this.dataSource.Type.Name : "") +
        (this.dataSource.Nomer ? " №" + this.dataSource.Nomer : "") +
        (this.dataSource.Data
          ? " от " + this.dataSource.Data.substr(0, 10)
          : "")
      );
    },
    // Роль текущего сотрудника
    isAuthor() {
      return (
        this.dataSource.Avtory.findIndex(
          (a) => a.Id === this.USER.Employee.Id
        ) !== -1
      );
    },
    isAgreementer() {
      return (
        this.dataSource.Soglasuyut.findIndex(
          (a) => a.Id === this.USER.Employee.Id
        ) !== -1
      );
    },
    isApprover() {
      return (
        this.dataSource.Podpisyvaet_utverzhdaet?.Id === this.USER.Employee.Id
      );
    },

    // Доступ к действиям ознакомляющего
    activeAcquainted() {
      const isAcquaintedList = this.dataSource.IsAcquaintanceEnabled;
      return (
        this.dataSource.Status === 5 &&
        !this.dataSource.Acquainted &&
        // Если сотрудник является согласующим или утверждающим и не входит явно в список ознакомления, то считается, что он уже ознакомлен с документом.
        !((this.isAgreementer || this.isApprover) && !isAcquaintedList) &&
        isAcquaintedList
      );
    },

    // Доступ к действиям автора
    activeAuthorDraft() {
      return (
        this.isAuthor &&
        (this.dataSource.Status === statusOnAgreement ||
          this.dataSource.Status === statusAgreed ||
          this.dataSource.Status === statusOnApproval)
      );
    },
    activeAuthorOnAgreement() {
      return (
        this.isAuthor &&
        this.dataSource.Status === statusDraft &&
        this.dataSource.Fields.findIndex((f) => f.Name === "Soglasuyut") >= 0
      );
    },
    activeAuthorOnApproval() {
      return (
        this.isAuthor &&
        (this.dataSource.Status === statusAgreed ||
          (this.dataSource.Status === statusDraft &&
            !this.dataSource.Soglasuyut.length))
      );
    },
    // Доступ к действиям согласующего
    activeAgreementerAgree() {
      return (
        this.isAgreementer &&
        this.currentSign === null &&
        (this.dataSource.Status === statusOnAgreement ||
          this.dataSource.Status === statusAgreed)
      );
    },
    activeAgreementerDisagree() {
      return (
        this.isAgreementer &&
        this.currentSign === null &&
        (this.dataSource.Status === statusOnAgreement ||
          this.dataSource.Status === statusAgreed)
      );
    },
    activeAgreementerCancel() {
      return (
        this.isAgreementer &&
        this.currentSign !== null &&
        (this.dataSource.Status === statusOnAgreement ||
          this.dataSource.Status === statusAgreed)
      );
    },
    // Доступ к действиям утверждающего
    activeApproverApprove() {
      return (
        this.isApprover &&
        this.currentSign === null &&
        this.dataSource.Status === statusOnApproval
      );
    },
    activeApproverOnAgreement() {
      return (
        this.isApprover &&
        this.currentSign === null &&
        this.dataSource.Status === statusOnApproval &&
        this.dataSource.Fields.findIndex((f) => f.Name === "Soglasuyut") >= 0
      );
    },
    activeApproverToDraft() {
      return (
        this.isApprover &&
        this.currentSign === null &&
        this.dataSource.Status === statusOnApproval
      );
    },
    activeApproverCancel() {
      return (
        this.isApprover &&
        this.currentSign !== null &&
        this.dataSource.Status === statusApproved
      );
    },
    // Доступ к регистрации и отмене документа
    activeActive() {
      return (
        (this.dataSource.Status === statusApproved &&
          (this.isApprover ||
            this.Author ||
            this.hasPermission(Permissions.OrganizationDocumentActive))) ||
        (this.dataSource.Status === statusCancelled &&
          (this.isApprover ||
            this.hasPermission(Permissions.OrganizationDocumentCancel)))
      );
    },
    activeCancelled() {
      return (
        (this.isApprover ||
          this.hasPermission(Permissions.OrganizationDocumentCancel)) &&
        this.dataSource.Status === statusActive
      );
    },
    // Доступ к добавлению (удалению) прикрепленных документов
    activeAdd() {
      return this.hasPermission(Permissions.OrganizationAllDocumentsEdit);
    },
    // Решение (подпись) текущего сотрудника (true, false или null)
    currentSign() {
      let signer;
      if (
        this.dataSource.Status === statusOnAgreement ||
        this.dataSource.Status === statusAgreed
      )
        signer = this.dataSource.Soglasuyut.find(
          (a) => a.Id === this.USER.Employee.Id
        );
      else if (
        this.dataSource.Status === statusOnApproval ||
        this.dataSource.Status === statusApproved
      )
        signer =
          this.dataSource.Podpisyvaet_utverzhdaet?.Id === this.USER.Employee.Id
            ? this.dataSource.Podpisyvaet_utverzhdaet
            : null;
      return signer?.Signed;
    },
    // Наличие положительного решения кого-либо из согласующих
    hasAgreementSigns() {
      return this.dataSource.Soglasuyut.findIndex((a) => a.Signed) >= 0;
    },
    // Доступ к редактированию комментариев
    editHistory() {
      return (
        (this.isAuthor ||
          this.isAgreementer ||
          this.isApprover ||
          this.activeActive) &&
        this.dataSource.Status < statusActive
      );
    },
    OrganizationDocumentType: () => {
      return OrganizationDocumentType;
    },
    OrganizationDocumentStatus: () => {
      return OrganizationDocumentStatus;
    },
    DocumentTemplateService: () => {
      return DocumentTemplateService;
    },
    fields() {
      const fields = {};
      if (this.dataSource?.Fields) {
        for (const field of this.dataSource.Fields) {
          fields[field.Name] = field;
        }
      }
      return fields;
    },
  },

  async mounted() {
    await this.init();

    this.employees = await this.LOAD_EMPLOYEES();
  },

  methods: {
    ...mapActions(["LOAD_EMPLOYEES"]),
    async customAdd(e) {
      this.showTabs = true;
      await new Promise((resolve) => {
        setTimeout(resolve, 100);
      });
      this.tab = 1;
      await new Promise((resolve) => {
        setTimeout(resolve, 100);
      });
      this.$refs.taskList.customAdd(e);
    },
    DataFromServerNormalize(data) {
      const { ...d } = data;
      this.Content = d.Content;

      setTimeout(() => {
        if (d.IsContentVisible) {
          this.showDocument = { title: "Текст", name: "text", id: -1 };
        } else {
          const f = d?.Files;

          if (f?.length) {
            this.showDocument = { id: f[0].Id, name: f[0].Name };
          }
        }
      }, 1000);

      // Временно. Указывает локальный ли документ
      d.RatifyingDocumentIsOrganizationDocument = true;

      if (d.Fields) {
        for (const field of d.Fields) {
          if (d[field.Name]) {
            field.Value = d[field.Name];
          }
        }
      }
      return d;
    },

    // Копирование документа
    CopyDataNormalize(data) {
      // Переводим в черновик
      data.Status = 0;
      // Добавляем текущего сотрудника в авторы, если его там нет.
      if (data.Avtory.findIndex((a) => a.Id === this.USER.Employee.Id) === -1)
        data.Avtory.push(this._.cloneDeep(this.USER.Employee));

      // Очищаем дату и номер
      data.Date = defaultLocalDocument().Date;
      data.Num = null;
      // Устанавливаем доступ ко всем полям
      for (const field of data.Fields) field.Enabled = true;
      return data;
    },

    // Действия ознакомляющего
    async setAcquainted() {
      try {
        await LocalDocumentService.acquainted(this.dataSource.Id);
        this.dataSource.Acquainted =
          new Date().toLocaleDateString() +
          " " +
          new Date().toLocaleTimeString();
        this.originObject.Acquainted = this.dataSource.Acquainted;
      } catch (error) {
        console.log(error);
      }
    },

    // Действия автора
    async authorDraft() {
      if (
        await this.$confirm(
          `Вы действительно хотите вернуть документ в 'Черновик'?`
        )
      )
        await this.setStatus(statusDraft);
      this.init();
    },
    async authorOnAgreement(event) {
      await this.setStatus(statusOnAgreement, event);
      this.init();
    },
    async authorOnApproval(event) {
      await this.setStatus(statusOnApproval, event);
    },
    // Действия согласующего
    async agreementerAgree() {
      await this.setSign(true);
      this.init();
    },
    async agreementerDisagree(event) {
      await this.setSign(false, event);
      this.init();
    },
    async agreementerCancel() {
      await this.setSign(null);
      this.init();
    },
    // Действия утверждающего
    async approverApprove() {
      await this.setSign(true);
      this.init();
    },
    async approverOnAgreement(event) {
      await this.setSign(false, { onAgreement: true, ...event });
      this.init();
    },
    async approverToDraft(event) {
      await this.setSign(false, event);
      this.init();
    },
    async approverCancel() {
      await this.setSign(null);
      this.init();
    },
    // Регистрация и отмена документа
    async setActive() {
      if (
        await this.$confirm(
          `Вы действительно хотите зарегистрировать документ?`
        )
      )
        await this.setStatus(statusActive);
      await this.init();
    },
    async setCancelled(event) {
      await this.setStatus(statusCancelled, event);
    },
    // Изменение статуса
    async setStatus(status, options) {
      const { data } = await LocalDocumentService.updateStatus(
        this.dataSource.Id,
        status,
        options
      );
      this.updateSource(data);
    },
    // Изменение решения
    async setSign(sign, options) {
      const { data } = await LocalDocumentService.updateSign(
        this.dataSource.Id,
        sign,
        options
      );
      this.updateSource(data);
    },
    // Неполное обновление данных
    updateSource(data) {
      // Доступ к полям
      for (const i in data.Fields) {
        this.dataSource.Fields[i].Enabled = data.Fields[i].Enabled;
        this.originObject.Fields[i].Enabled = data.Fields[i].Enabled;
      }
      // Согласующие и подписи
      for (const agreementer of data.Soglasuyut) {
        const index = this.dataSource.Soglasuyut.findIndex(
          (a) => a.Id === agreementer.Id
        );
        if (index >= 0) {
          this.dataSource.Soglasuyut[index].Signed = agreementer.Signed;
          this.originObject.Soglasuyut[index].Signed = agreementer.Signed;
        } else {
          this.dataSource.Soglasuyut.push(agreementer);
          this.originObject.Soglasuyut.push(agreementer);
        }
      }
      if (data.Podpisyvaet_utverzhdaet) {
        this.dataSource.Podpisyvaet_utverzhdaet.Signed =
          data.Podpisyvaet_utverzhdaet.Signed;
        this.originObject.Podpisyvaet_utverzhdaet.Signed =
          data.Podpisyvaet_utverzhdaet.Signed;
      }
      // Статус
      this.dataSource.Status = data.Status;
      this.originObject.Status = data.Status;
      // История изменений
      this.$refs.history.refresh();
    },
    async init() {
      this.baseInit();
      this.$refs.history.refresh();
      this.$refs.acquaintance.refresh();
    },
    async updateDataView() {
      if (this.type === "create") {
        const { data } = await LocalDocumentService.new(
          this.$route.query?.templateId ?? 0
        );
        this.dataSource = this.DataFromServerNormalize(data);
      }
    },
    updateField: debounce(function (params) {
      if (this.dataSource[params.name] !== undefined)
        this.$set(this.dataSource, params.name, params.value);

      const i = this.dataSource.Fields.findIndex((e) => e.Name === params.name);

      if (i !== -1) {
        this.dataSource.Fields[i].Value = params.value;
        this.$set(this.dataSource.Fields, i, this.dataSource.Fields[i]);
      }
    }, 30),
    async saveAddText() {
      this.loading = true;

      const res = await this.baseSave();
      return res;
    },
    async save() {
      this.loading = true;
      if (
        this.dataSource.IsContentVisible &&
        this.$refs.fileLibrary.$refs.rich
      ) {
        this.dataSource.Content =
          await this.$refs.fileLibrary.$refs.rich.getDocumentContent();
        this.originObject.Content = this.dataSource.Content;
      }

      const res = await this.baseSave();
      return res;
    },
    documentChanged(val) {
      if (val.document.modified) {
        this.$set(this.dataSource, "isModified", true);
      } else {
        delete this.dataSource.isModified;
      }
    },
  },
};
</script>

<style lang="sass">
.local-document-edit__help-panel
  text-align: right
</style>
