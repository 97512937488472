var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.fields)?_c('div',{staticClass:"dynamic-inputs__wprapper",class:_vm.classWrapper},[_vm._l((_vm.fields.filter((e) => e.Name !== 'Content' && !e.Hidden)),function(field){return [_c('div',{key:field.Name,staticClass:"dynamic-inputs__item",class:{ 'px-3': _vm.fixedWidth },style:(_vm.fixedWidth
          ? 'display: flex; width: ' +
            field.Width * 100 +
            '%;     height: 100%;'
          : '')},[(field.Type === 1 || field.Type === 2)?_c(_vm.getComp(field),_vm._b({directives:[{name:"up-first-symbol",rawName:"v-up-first-symbol"}],tag:"component",attrs:{"label":field.DisplayName || field.Name,"readonly":_vm.$attrs['readonly'] ||
          !(field.Enabled === undefined || field.Enabled)},on:{"input":(value) => _vm.handleInput(field.Name, value)},model:{value:(_vm.dataSource[field.Name]),callback:function ($$v) {_vm.$set(_vm.dataSource, field.Name, $$v)},expression:"dataSource[field.Name]"}},'component',_vm.getBind(field),false)):_c(_vm.getComp(field),_vm._b({directives:[{name:"up-first-symbol",rawName:"v-up-first-symbol"}],tag:"component",attrs:{"label":field.DisplayName || field.Name,"readonly":_vm.$attrs['readonly'] ||
          !(field.Enabled === undefined || field.Enabled)},on:{"input":(value) => _vm.handleInput(field.Name, value)},model:{value:(_vm.dataSource[field.Name]),callback:function ($$v) {_vm.$set(_vm.dataSource, field.Name, $$v)},expression:"dataSource[field.Name]"}},'component',_vm.getBind(field),false))],1)]})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }